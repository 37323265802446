<template>
  <HeaderBar />
  <!--<Header/>-->
  <div>
    <div class="cabecera-continue">
      <div class="container">
        <div class="row">
          <div class="comun-entry col-12">
            <div class="form-title text-center col-xl-12">
              <h2 class="form-subtitle">{{$t('help.title')}}</h2>
              <div class="divider-wrap col-12">
                <div class="divider-middle mx-auto"></div>
              </div>
              <div class="form-text offset-lg-1 col-lg-10">
                <p class="form-p">
                  {{$t('help.subtitle')}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container help">
      <div class="row">
        <div class="title-img text-center col-12 offset-lg-1 col-lg-11">
          <img src="@/assets/img/help-img-title.png" alt="" class="" />
        </div>
        <div class="accordion col-12 offset-lg-1 col-lg-11" id="accordionHelp">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left panel-title collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {{$t('help.sectionOne.question')}}
                  <div class="img-open d-lg-none">
                    <img
                      src="@/assets/img/help-panel-open.png"
                      alt=""
                      class="help-panel-open mx-auto d-block"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionHelp"
            >
              <div class="card-body">
                <p class="panel-text" v-html="$t('help.sectionOne.answer')">
                </p>
                <div class="img-close d-lg-none">
                  <img
                    src="@/assets/img/help-panel-close.png"
                    alt=""
                    class="help-panel-open mx-auto d-block"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  class="btn btn-block text-left collapsed panel-title"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  {{$t('help.sectionTwo.question')}}
                  <div class="img-open d-lg-none">
                    <img
                      src="@/assets/img/help-panel-open.png"
                      alt=""
                      class="help-panel-open mx-auto d-block"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionHelp"
            >
              <div class="card-body">
                <p class="panel-text" v-html="$t('help.sectionTwo.answer')">
                </p>
                <div class="img-close d-lg-none">
                  <img
                    src="@/assets/img/help-panel-close.png"
                    alt=""
                    class="help-panel-open mx-auto d-block"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  class="btn btn-block text-left collapsed panel-title"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{$t('help.sectionThree.question')}}
                  <div class="img-open d-lg-none">
                    <img
                      src="@/assets/img/help-panel-open.png"
                      alt=""
                      class="help-panel-open mx-auto d-block"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionHelp"
            >
              <div class="card-body">
                <p class="panel-text" v-html="$t('help.sectionThree.answer')">
                </p>
                <div class="img-close d-lg-none">
                  <img
                    src="@/assets/img/help-panel-close.png"
                    alt=""
                    class="help-panel-open mx-auto d-block"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour">
              <h2 class="mb-0">
                <button
                  class="btn btn-block text-left collapsed panel-title"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  {{$t('help.sectionFour.question')}}
                  <div class="img-open d-lg-none">
                    <img
                      src="@/assets/img/help-panel-open.png"
                      alt=""
                      class="help-panel-open mx-auto d-block"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionHelp"
            >
              <div class="card-body">
                <p class="panel-text" v-html="$t('help.sectionFour.answer')">
                </p>
                <div class="img-close d-lg-none">
                  <img
                    src="@/assets/img/help-panel-close.png"
                    alt=""
                    class="help-panel-open mx-auto d-block"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h2 class="mb-0">
                <button
                  class="btn btn-block text-left collapsed panel-title"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  {{$t('help.sectionFive.question')}}
                  <div class="img-open d-lg-none">
                    <img
                      src="@/assets/img/help-panel-open.png"
                      alt=""
                      class="help-panel-open mx-auto d-block"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionHelp"
            >
              <div class="card-body">
                <p class="panel-text" v-html="$t('help.sectionFive.answer')">
                </p>
                <div class="img-close d-lg-none">
                  <img
                    src="@/assets/img/help-panel-close.png"
                    alt=""
                    class="help-panel-open mx-auto d-block"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h2 class="mb-0">
                <button
                  class="btn btn-block text-left collapsed panel-title"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  {{$t('help.sectionSix.question')}}
                  <div class="img-open d-lg-none">
                    <img
                      src="@/assets/img/help-panel-open.png"
                      alt=""
                      class="help-panel-open mx-auto d-block"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionHelp"
            >
              <div class="card-body">
                <p class="panel-text" v-html="$t('help.sectionSix.answer')">
                </p>
                <div class="img-close d-lg-none">
                  <img
                    src="@/assets/img/help-panel-close.png"
                    alt=""
                    class="help-panel-open mx-auto d-block"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h2 class="mb-0">
                <button
                  class="btn btn-block text-left collapsed panel-title"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  {{$t('help.sectionSeven.question')}}
                  <div class="img-open d-lg-none">
                    <img
                      src="@/assets/img/help-panel-open.png"
                      alt=""
                      class="help-panel-open mx-auto d-block"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionHelp"
            >
              <div class="card-body">
                <p class="panel-text" v-html="$t('help.sectionSeven.answer')">
                </p>
                <div class="img-close d-lg-none">
                  <img
                    src="@/assets/img/help-panel-close.png"
                    alt=""
                    class="help-panel-open mx-auto d-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="background-form">
      <div class="container">
        <div class="bloque-informacion-basica">
          <div class="row align-items-start">
            <div class="informacion-basica-titulo col-12">
              <h2 class="informacion-basica-texto">{{$t('help.contact.title')}}</h2>
              <div class="divider-wrap">
                <div class="divider-middle mx-auto"></div>
              </div>
            </div>
            <div class="bloque-info-lines no-form "> 
              <p class="bloque-info col-12 text-center">{{$t('help.contact.body')}}</p> 
            </div>
            <div class="col-12 offset-md-1 col-md-11 offset-md-1 offset-xl-2 col-xl-9"> 
              <button @click="sendMailContact()" type="submit" class="rounded-pill btn-lg btn-primary mb-2 form-btn-submit offset-md-1 offset-xl-8 col-md-5">{{$t('help.contact.button')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
//import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Login",
  components: {
    HeaderBar,
    //Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    sendMailContact() {
      window.location.href = "mailto:Admissions@theglobalcollege.com";
    }
  }
};
</script>